<template>
  <v-list-group
    class="relative nav-group"
    :value="item.name"
    :subgroup="subgroup"
  >
    <template v-slot:activator="{ props }">
      <nav-item @click.prevent="navigate" v-bind="props" :item="item" />
    </template>

    <template v-for="(child, i) in item.items" :key="i">
      <NavGroup
        v-if="isGroup(child)"
        :item="child"
        class="nav-group-item"
        subgroup
      />
      <NavItem v-else :item="child" class="group nav-group-item" />
    </template>
  </v-list-group>
</template>

<script lang="ts">
import NavItem from "./NavItem.vue";
import { isGroup } from "./utils";
import type { AppNavGroup } from "./types";

export default defineComponent({
  components: { NavItem },
  props: {
    item: { type: Object as PropType<AppNavGroup>, required: true },
    subgroup: { type: Boolean, default: false },
  },
  methods: {
    isGroup,
    navigate() {
      if (this.item.route) {
        this.$router.push(this.item.route!);
      }
    },
  },
});
</script>
