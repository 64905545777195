<template>
  <v-list-item :title="item.name" :to="item.route" rounded="lg">
    <template #prepend="props">
      <slot name="prepend" v-bind="props">
        <AppIcon
          v-if="item.activeIcon && item.icon"
          :name="props.isActive ? item.activeIcon : item.icon"
          size="16"
          class="mr-2"
          :class="{ 'opacity-70': !props.isActive }"
        />
      </slot>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import type { AppNavGroup, AppNavRootItem, AppNavItem } from "./types";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<AppNavGroup | AppNavRootItem | AppNavItem>,
      required: true,
    },
  },
});
</script>
